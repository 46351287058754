'use client';

import FormTable from '~/app/_components/form/FormTable/FormTable';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
} from '~/components/ui/breadcrumb';
import { SidebarTrigger } from '~/components/ui/sidebar';

const DashboardTeamPage = () => {
  return (
    <>
      <header className="flex h-16 shrink-0 items-center gap-2 transition-[width,height] ease-linear group-has-[[data-collapsible=icon]]/sidebar-wrapper:h-12">
        <div className="flex items-center gap-2 px-4">
          <SidebarTrigger className="-ml-1" />
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem className="hidden md:block">
                <BreadcrumbLink href="/" className="flex items-center gap-1.5">
                  <img className="h-4" src="/logo.svg" alt="logo" />
                </BreadcrumbLink>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
        </div>
      </header>
      <div className="border-t border-black/10"></div>
      <div className="flex flex-1 flex-col gap-4 p-1 max-h-screen w-full overflow-auto">
        <main className="flex h-full max-h-full w-full max-w-full flex-col overflow-clip px-6 py-1">
          <FormTable />
        </main>
      </div>
    </>
  );
};

export default DashboardTeamPage;
